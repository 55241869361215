
import Vue from 'vue'
import SERVICES_CONSTANTS from '@/constants/services'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { emailAllowedSymbolsRegExp, passwordAllowedSymbolsRegExp } from '@/constants/baseConstatnts'

export default Vue.extend({
  name: 'loginPage',
  data: () => ({
    form: {
      valid: false,
      fields: {
        email: {
          value: '',
          rules: [
            (v: string) => Boolean(v.length) || 'login.errors.loginRequired',
            (v: string) => emailAllowedSymbolsRegExp.test(v.trim()) || 'login.errors.emailIncorrect'
          ],
          isAutofilled: false,
          pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
        },
        password: {
          value: '',
          type: 'password',
          rules: [
            (v: string) => Boolean(v.length) || 'login.errors.passwordRequired'
          ],
          isAutofilled: false,
          pattern: passwordAllowedSymbolsRegExp.source
        }
      },
      successLogin: false,
      successTimer: 0,
      responseErrors: [],
      specialErrors: {
        wrongService: false
      },
      isLoading: false
    },
    productAuthToken: ''
  }),
  watch: {
    'form.successTimer' (val: number, oldVal: number) {
      const vm = this as any
      if (val === 0 && oldVal === 1) {
        const acceptor = localStorage.getItem('acceptor')
        if (acceptor) {
          location.href = acceptor
        } else {
        }
      }
    }
  },
  methods: {
    handleAutofill (scope: 'email' | 'password', e: any): void {
      const vm = this as any

      const autofillAnims = ['autofillDark', 'autofillLight']
      const isAutofilled = autofillAnims.includes(e.animationName)

      if (isAutofilled) {
        vm.form.fields[scope].isAutofilled = true
      }
    },
    handleChange (scope: 'email' | 'password', v: string):void {
      const vm = this as any

      vm.$refs.form.validate()
      vm.form.fields[scope].value = v
      vm.form.fields[scope].isAutofilled = false
    },
    async validate (): Promise<void> {
      const vm = this as any
      vm.form.isLoading = true

      const request: any = {}
      for (const [key, value] of Object.entries(vm.form.fields) as any) {
        request[key] = value.value
      }
      if (this.productAuthToken) request.product_auth_token = this.productAuthToken
      const response = await vm.$services.authorization.signin.doSignIn(request)
      if (response.success) {
        /* window.ym(89268028, 'reachGoal', 'Sign In')
        window.ym(93312770, 'reachGoal', 'Sign In') */
        if (response.status === 200) {
          vm.handleSuccessLogin(response.data)
        } else {
          if (!response.data.session_id) {
            this.$router.push({ name: 'AccessRepair', query: { recoveryId: response.data.recovery_id } })
            return
          }
          const method = Object.keys(response.data.confirmations)[0]
          vm.$router.push({ name: 'SignInSecondFactor', params: { method, clientToken: this.$route.params.clientToken }, query: { an3K8QkkJb3Mzz: response.data.session_id } })
        }
      } else {
        vm.form.responseErrors = []
        if (response.status === 418) {
          vm.form.specialErrors.wrongService = true
        } else {
          vm.form.specialErrors.wrongService = false
          for (const [key, value] of Object.entries(response.data) as any) {
            for (const err of value) {
              vm.form.responseErrors.push(err)
            }
          }
        }
      }

      vm.form.isLoading = false
    },
    handleSuccessLogin (responseData: { [key: string]: string }): void {
      const vm = this as any
      const location = window.location
      const host = location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0]
      vm.$cookies.set('jwtr', responseData.refresh_token, {
        expires: moment.unix((jwtDecode(responseData.refresh_token) as any).exp).toDate(),
        domain: '.' + host
      })
      vm.form.successLogin = true
      if (this.$route.params.clientToken) {
        this.$store.dispatch('user/loadUser')
        this.$router.replace({ name: 'authentication', query: { clientToken: this.$route.params.clientToken } })
      } else {
        vm.$store.commit('setAppIsRedirecting', true)
        vm.form.successTimer = 1
        setInterval(() => {
          if (vm.form.successTimer > 0) vm.form.successTimer--
        }, 1000)
        const acceptor = localStorage.getItem('acceptor')
        window.location.href = (acceptor || SERVICES_CONSTANTS.ACCEPTOR_BASE_URL)
      }
    },
    changePasswordInputType (): void {
      const vm = this as any
      vm.form.fields.password.type = vm.form.fields.password.type === 'password' ? 'text' : 'password'
    },
    handleClickRedirectToWhitelist (): void {
      window.open('https://passport.whitelist.capital/', '_self')
    }
  },
  created (): void {
    this.productAuthToken = this.$route.query.product_auth_token
    if (this.$route.query.errors === 'wrongService') {
      this.form.specialErrors.wrongService = true
      this.$router.replace({ query: {} })
    }
  }
})
